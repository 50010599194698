export const socialLinks = [
  {
    id: 1,
    name: "Website",
    icon: "globe",
    url: "https://www.davidfuhry.com/",
  },
  {
    id: 2,
    name: "GitHub",
    icon: "github",
    url: "https://github.com/davidfuhry",
  },
  {
    id: 3,
    name: "LinkedIn",
    icon: "linkedin",
    url: "https://www.linkedin.com/in/david-fuhry/",
  },
];
